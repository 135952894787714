import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import Bronze from "../../../../assets/images/banner/vip-level/Bronze.jpg";
import Silverr from "../../../../assets/images/banner/vip-level/Silver.jpg";
import Gold from "../../../../assets/images/banner/vip-level/Gold.jpg";
import Platinum from "../../../../assets/images/banner/vip-level/Platinum.jpg";

function VIPLevel() {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="vip-gifts vip-level">
          <div className="inner-box">
            <div className="content-box">
              <div className="heading">VIP level</div>

              <ul>
                <li>
                  <div className="">
                    <img src={Bronze} alt="bronze" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={Silverr} alt="silverr" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={Gold} alt="gold" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={Platinum} alt="platinum" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
}

export default VIPLevel;
