import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import ModalWrapper from "./ModalWrapper";
import AviatorLogo from "../assets/images/icons/aviator-logo.svg";
import RegistrationIcon from "../assets/images/icons/registration-icon.svg";
import ArrowRight from "../assets/images/icons/arrow-right.svg";
import GiftIcon from "../assets/images/icons/gift-icon.svg";
import Bunuses from "../assets/images/icons/bunuses.png";
import LuckyDrawIcon from "../assets/images/icons/lucky-draw-icon.svg";
import WinningItem from "../assets/images/icons/winning-item.png";
import WindowsImg from "../assets/images/icons/windows-img.svg";
import AndroidIcon from "../assets/images/icons/android-apk.svg";
import AppleIcon from "../assets/images/icons/apple-apk.svg";
import UpArrow from "../assets/images/icons/up-arrow.svg";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LanguageChange from "../views/components/LanguageChange";

const headerPages = [
  "home",
  "betby",
  "sports",
  "casinogames",
  "livecasino",
  "aviator",
  "evolution",
  "ezugi",
  "supernowa",
  "vivo",
  "xpg",
  "worldcasino",
  "qtech",
  "/",
];

const Header = () => {
  const { appDetails } = useSelector((state) => state.app);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (currentScrollTop > lastScrollTop && currentScrollTop > 0) {
        // Scrolling down
        setIsSticky(true);
      } else if (currentScrollTop <= 0) {
        // Remove sticky class when scrolled all the way up
        setIsSticky(false);
      }
      setLastScrollTop(currentScrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <header className="header beforeheader">
      <div className="top_head d-none d-lg-block">
        <div className="inner-box">
          <div className="left-section">
            <a href="/vip-level" className="vip-level">
              <span>VIP level</span>
              <span className="vip-icon"></span>
              <img src={ArrowRight} alt="right arrow" />
            </a>
          </div>

          <div className="right-section">
            <div className="vip-gift">
              <a href="/promotions" className="promotion">
                <div className="img-box">
                  <img src={GiftIcon} alt="promotions" />
                </div>
                <span>Promotions and bonuses</span>
                <img src={Bunuses} alt="bunuses" className="bonus" />
              </a>
              <a href="/lucky-draw" className="lucky-draw">
                <div className="img-box">
                  <img src={LuckyDrawIcon} alt="lucky draw" />
                </div>
                <span>VIP Lucky Draw</span>
                <img
                  src={WinningItem}
                  alt="winning item"
                  className="win-itme"
                />
              </a>
            </div>

            <div className="vip-apk">
              <div className="window-apk">
                <div>
                  <h6>Application</h6>
                  <p>for Windows</p>
                </div>
                <a href="javascript:void(0)">
                  <img src={WindowsImg} alt="windows" />
                </a>
              </div>
              <div className="mobile-apk">
                <div className="app-btn">
                  <img src={AndroidIcon} alt="android apk" />
                </div>
                <div className="app-btn">
                  <img src={AppleIcon} alt="apple apk" />
                </div>
                <div className="tooltip-content">
                  <div className="up-arrow">
                    <img src={UpArrow} alt="up arrow" />
                  </div>
                  <div className="content-box">
                    <p>
                      Visit the site from your smartphone, install the
                      application right now and get INR 200 to the bonus
                      account!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="language">
              <LanguageChange />
            </div>
          </div>
        </div>
      </div>

      <div className={`bottom_head ${isSticky ? "sticky" : ""}`}>
        <div className="inner-box">
          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              {/* <img src={Logo} alt="Logo" /> */}
            </a>
          </div>

          <div className="head-links">
            <ul>
              <li>
                <a
                  href="/"
                  className={location.pathname === "/" ? "active" : ""}
                >
                  <span>Home</span>
                </a>
              </li>
              <li className="allGames">
                <a
                  href="/betby"
                  className={activePage == "betby" ? "active" : ""}
                >
                  <span>Sports</span>
                </a>
              </li>
              <li className="allGames">
                <a
                  href="/sports/Inplay"
                  className={activePage == "sports" ? "active" : ""}
                >
                  <span>Exchange</span>
                </a>
              </li>
              <li>
                <a
                  href="/casinogames"
                  className={activePage == "casinogames" ? "active" : ""}
                >
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a
                  href="/livecasino"
                  className={activePage == "livecasino" ? "active" : ""}
                >
                  <span>Live-games</span>
                </a>
              </li>
              <li>
                <a
                  href="casino/spribe/aviator"
                  className={`game-logo ${
                    activePage == "aviator" ? "active" : ""
                  }`}
                >
                  <span>
                    <img src={AviatorLogo} alt="aviator" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/evolution"
                  className={activePage == "evolution" ? "active" : ""}
                >
                  <span>Evolution</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/ezugi"
                  className={activePage == "ezugi" ? "active" : ""}
                >
                  <span>Ezugi</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/supernowa"
                  className={activePage == "supernowa" ? "active" : ""}
                >
                  <span>Supernowa</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/vivo"
                  className={activePage == "vivo" ? "active" : ""}
                >
                  <span>Vivo</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/xpg"
                  className={activePage == "xpg" ? "active" : ""}
                >
                  <span>XPG</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/worldcasino"
                  className={activePage == "worldcasino" ? "active" : ""}
                >
                  <span>Worldcasino</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/qtech"
                  className={activePage == "qtech" ? "active" : ""}
                >
                  <span>Qtech</span>
                </a>
              </li>
            </ul>

            <div className="d-lg-none">
              <GamesSearchModal />
            </div>
          </div>

          <div className="rightSec ms-lg-auto">
            <Button
              variant=""
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
              className="btn_signin"
            >
              Login
            </Button>
            <Button
              variant=""
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
              }}
              className="btn_signup"
            >
              <span className="d-none d-lg-inline-flex">
                <img src={RegistrationIcon} alt="registration" />
              </span>
              Registration
            </Button>
          </div>
        </div>
      </div>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModals}
        />
      )}
    </header>
  );
};

export default Header;
