import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import VipPromotion1 from "../../../../assets/images/banner/promotions/VipPromotion1.jpg";
import VipPromotion2 from "../../../../assets/images/banner/promotions/VipPromotion2.jpg";
import VipPromotion3 from "../../../../assets/images/banner/promotions/VipPromotion3.jpg";
import VipPromotion4 from "../../../../assets/images/banner/promotions/VipPromotion4.jpg";

function Promotions() {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="vip-gifts promotions">
          <div className="inner-box">
            <div className="content-box">
              <div className="heading">Promotions and bonuses</div>

              {/* <h5>Top 5 promoter of the month</h5> */}
              <ul>
                <li>
                  <div className="">
                    <img src={VipPromotion1} alt="bronze" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipPromotion2} alt="silverr" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipPromotion3} alt="gold" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipPromotion4} alt="platinum" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
}

export default Promotions;
