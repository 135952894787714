import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../redux/auth/actions";
import { Dropdown, Button, Modal } from "react-bootstrap";
import numberWithCommas from "../utils/numbersWithComma";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import DepositIcon from "../assets/images/icons/deposit-icon.svg";
import RupeeIcon from "../assets/images/icons/rupee-icon.svg";
import ArrowDown from "../assets/images/icons/arrow-down.svg";
import UserIcon from "../assets/images/icons/user-icon.svg";
import Logo from "../assets/images/logo.png";
import AviatorLogo from "../assets/images/icons/aviator-logo.svg";
import ArrowRight from "../assets/images/icons/arrow-right.svg";
import GiftIcon from "../assets/images/icons/gift-icon.svg";
import Bunuses from "../assets/images/icons/bunuses.png";
import LuckyDrawIcon from "../assets/images/icons/lucky-draw-icon.svg";
import WinningItem from "../assets/images/icons/winning-item.png";
import WindowsImg from "../assets/images/icons/windows-img.svg";
import AndroidIcon from "../assets/images/icons/android-apk.svg";
import AppleIcon from "../assets/images/icons/apple-apk.svg";
import UpArrow from "../assets/images/icons/up-arrow.svg";
import GamesSearchModal from "../views/components/GamesSearchModal";
import LanguageChange from "../views/components/LanguageChange";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount, balance } = useSelector(
    (state) => state.auth
  );

  const { betMatches, userBets } = useSelector((state) => state.sports);
  // const betMatches = [
  //   {
  //     exposureAmount: 6438.5,
  //     matchName: "Australia v West Indies",
  //     refMatchId: 10,
  //     totalBets: 2,
  //   },
  // ];
  // const userBets = [
  //   {
  //     odds: 130,
  //     stack: 50,
  //     selectionType: "back",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:13.000Z",
  //   },
  //   {
  //     odds: 1.23,
  //     stack: 50,
  //     selectionType: "lay",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:04.000Z",
  //   },
  // ];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);

  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);
  // function handelSubmit() {
  //   return <Index />;
  // }
  const [showToggle, setShowToggle] = useState(true);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);

  const handleRowClick = (item) => {
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "betby",
    "sports",
    "casinogames",
    "livecasino",
    "aviator",
    "evolution",
    "ezugi",
    "supernowa",
    "vivo",
    "xpg",
    "worldcasino",
    "qtech",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (currentScrollTop > lastScrollTop && currentScrollTop > 0) {
        // Scrolling down
        setIsSticky(true);
      } else if (currentScrollTop <= 0) {
        // Remove sticky class when scrolled all the way up
        setIsSticky(false);
      }
      setLastScrollTop(currentScrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <header className="header aftrlgn">
      <div className="top_head d-none d-lg-block">
        <div className="inner-box">
          <div className="left-section">
            <a href="/vip-level" className="vip-level">
              <span>VIP level</span>
              <span className="vip-icon"></span>
              <img src={ArrowRight} alt="right arrow" />
            </a>
          </div>

          <div className="right-section">
            <div className="vip-gift">
              <a href="/promotions" className="promotion">
                <div className="img-box">
                  <img src={GiftIcon} alt="promotions" />
                </div>
                <span>Promotions and bonuses</span>
                <img src={Bunuses} alt="bunuses" className="bonus" />
              </a>
              <a href="/lucky-draw" className="lucky-draw">
                <div className="img-box">
                  <img src={LuckyDrawIcon} alt="lucky draw" />
                </div>
                <span>VIP Lucky Draw</span>
                <img
                  src={WinningItem}
                  alt="winning item"
                  className="win-itme"
                />
              </a>
            </div>

            <div className="vip-apk">
              <div className="window-apk">
                <div>
                  <h6>Application</h6>
                  <p>for Windows</p>
                </div>
                <a href="javascript:void(0)">
                  <img src={WindowsImg} alt="windows" />
                </a>
              </div>
              <div className="mobile-apk">
                <div className="app-btn">
                  <img src={AndroidIcon} alt="android apk" />
                </div>
                <div className="app-btn">
                  <img src={AppleIcon} alt="apple apk" />
                </div>
                <div className="tooltip-content">
                  <div className="up-arrow">
                    <img src={UpArrow} alt="up arrow" />
                  </div>
                  <div className="content-box">
                    <p>
                      Visit the site from your smartphone, install the
                      application right now and get INR 200 to the bonus
                      account!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="language">
              <LanguageChange />
            </div>
          </div>
        </div>
      </div>

      <div className={`bottom_head ${isSticky ? "sticky" : ""}`}>
        <div className="inner-box">
          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              {/* <img src={Logo} alt="Logo" /> */}
            </a>
          </div>

          <div className="head-links">
            <ul>
              <li>
                <a
                  href="/home"
                  className={activePage == "home" ? "active" : ""}
                >
                  <span>Home</span>
                </a>
              </li>
              <li className="allGames">
                <a
                  href="/betby"
                  className={activePage == "betby" ? "active" : ""}
                >
                  <span>Sports</span>
                </a>
              </li>
              <li className="allGames">
                <a
                  href="/sports/Inplay"
                  className={activePage == "sports" ? "active" : ""}
                >
                  <span>Exchange</span>
                </a>
              </li>
              <li>
                <a
                  href="/casinogames"
                  className={activePage == "casinogames" ? "active" : ""}
                >
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a
                  href="/livecasino"
                  className={activePage == "livecasino" ? "active" : ""}
                >
                  <span>Live-games</span>
                </a>
              </li>
              <li>
                <a
                  href="casino/spribe/aviator"
                  className={`game-logo ${
                    activePage == "aviator" ? "active" : ""
                  }`}
                >
                  <span>
                    <img src={AviatorLogo} alt="aviator" />
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/evolution"
                  className={activePage == "evolution" ? "active" : ""}
                >
                  <span>Evolution</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/ezugi"
                  className={activePage == "ezugi" ? "active" : ""}
                >
                  <span>Ezugi</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/supernowa"
                  className={activePage == "supernowa" ? "active" : ""}
                >
                  <span>Supernowa</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/vivo"
                  className={activePage == "vivo" ? "active" : ""}
                >
                  <span>Vivo</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/xpg"
                  className={activePage == "xpg" ? "active" : ""}
                >
                  <span>XPG</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/worldcasino"
                  className={activePage == "worldcasino" ? "active" : ""}
                >
                  <span>Worldcasino</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/qtech"
                  className={activePage == "qtech" ? "active" : ""}
                >
                  <span>Qtech</span>
                </a>
              </li>
            </ul>

            <div className="d-lg-none">
              <GamesSearchModal />
            </div>
          </div>

          <div className="rightSec ms-lg-auto">
            <div className="bal-dpst">
              <div className="balance">
                <span>
                  INR
                  <Dropdown align="center" className="balanceDropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-balance">
                      <img src={ArrowDown} alt="balance" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="bal real">
                        <p>Main wallet</p>
                        <div className="box">
                          <div className="img-box">
                            <img src={RupeeIcon} alt="rupee" />
                          </div>
                          <div className="amnt">
                            {numberWithCommas(wallet?.balance)}
                          </div>
                          <div className="dpst">
                            <a href="/gatewaylist">
                              <img src={DepositIcon} alt="deposit" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="bal cash">
                        <p>Cash</p>
                        <div className="box">
                          <div className="img-box">
                            <img src={RupeeIcon} alt="rupee" />
                          </div>
                          <div className="amnt">
                            {numberWithCommas(wallet?.cash)}
                          </div>
                        </div>
                      </div>
                      <div className="bal bonus">
                        <p>Bonus</p>
                        <div className="box">
                          <div className="img-box">
                            <img src={RupeeIcon} alt="rupee" />
                          </div>
                          <div className="amnt">
                            {numberWithCommas(wallet?.coins)}
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
                <strong>{wallet?.balance}</strong>
              </div>

              <div className="dpst-btn">
                <a href="/gatewaylist" className="btn">
                  Deposit
                </a>
              </div>
            </div>

            <Dropdown align="end" className="userDropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={UserIcon} alt="user" />
                {/* {user?.mstruserid} */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item href="javascript:void(0)">
                  {user?.mstruserid}
                  <br />
                  <span>{numberWithCommas(wallet?.cash || 0)}</span>
                </Dropdown.Item> */}
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/withdraw">Withdrawal</Dropdown.Item>
                <Dropdown.Item href="/mybets">Bets History</Dropdown.Item>
                <Dropdown.Item href="/referral">Referral</Dropdown.Item>
                <Dropdown.Item href="/cashier">Statement</Dropdown.Item>
                <Dropdown.Item href="/" onClick={() => dispatch(logoutUser())}>
                  Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <Modal
        className="betslip_popup outer_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exposure amount</th>
                    <th scope="col">Match name</th>
                    <th scope="col">Ref match ID</th>
                    <th scope="col">Total bets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup inner_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Odds</th>
                    <th scope="col">Stack</th>
                    <th scope="col">Selection type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create date</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default HeaderAfterLogin;
