import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import UserIcon from "../../assets/images/icons/user-icon.svg";
import UserIcon2 from "../../assets/images/icons/user-icon2.svg";
import CloseIcon from "../../assets/images/icons/close-icon.svg";
import HomeIcon from "./../../assets/images/games-icon/home-icon.png";
import SportsIcon from "../../assets/images/icons/sports-cion.svg";
import Exchange from "../../assets/images/icons/leftbar/exchange.svg";
import CasinoIcon from "../../assets/images/icons/casino-icon.svg";
import LiveCasinoIcon from "../../assets/images/icons/live-casino-icon.svg";
import AviatorIcon from "../../assets/images/icons/aviator-icon.svg";
import AviatorLogo from "../../assets/images/icons/aviator-logo.svg";
import Evolution from "../../assets/images/icons/leftbar/evolution.svg";
import Ezugi from "../../assets/images/icons/leftbar/ezugi.svg";
import Supernowa from "../../assets/images/icons/leftbar/supernowa.svg";
import Vivo from "../../assets/images/icons/leftbar/vivo.svg";
import XPG from "../../assets/images/icons/leftbar/xpg.svg";
import Worldcasino from "../../assets/images/icons/leftbar/worldcasino.svg";
import Qtech from "../../assets/images/icons/leftbar/qtech.svg";
import { Button } from "react-bootstrap";
import { APP_CONST } from "../../config/const";
import ModalWrapper from "../../containers/ModalWrapper";
import { handleLeftSideBar } from "../../redux/app/actions";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const { showLeftSideBar } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleModalShow = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: true }));
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  const handleModalClose = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: false }));
    setShowAuthModals(false);
  };

  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "home",
    "betby",
    "sports",
    "casinogames",
    "livecasino",
    "aviator",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <aside className="side-menu">
        <div className="leftbarSec">
          <div className="close-bar">
            <a
              href="javascript:void(0)"
              className="close-btn"
              onClick={() => dispatch(handleLeftSideBar(!showLeftSideBar))}
            >
              <img src={CloseIcon} alt="close" />
            </a>
          </div>

          {!isAuth && (
            <div className="user bfr-lgn">
              <div className="user-login">
                <div className="img-box">
                  <img src={UserIcon2} alt="user" />
                </div>

                <div className="signup">
                  <Button
                    variant=""
                    onClick={() => {
                      handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                    }}
                    className="btn_signup"
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          )}

          {isAuth && (
            <div className="user aftr-lgn">
              <div className="user-img">
                <img src={UserIcon} alt="user" />
              </div>

              <div className="user-name">
                <h5>{user?.mstruserid}</h5>
                <h6>{user?.mobileno}</h6>
              </div>
            </div>
          )}

          <div className="menu-link">
            <ul>
              <li>
                <a
                  href="/home"
                  className={activePage == "home" ? "active" : ""}
                >
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  href="/betby"
                  className={activePage == "betby" ? "active" : ""}
                >
                  <img src={SportsIcon} alt="Sports" />
                  <span>Sports</span>
                </a>
              </li>
              <li>
                <a
                  href="/sports/Inplay"
                  className={activePage == "sports" ? "active" : ""}
                >
                  <img src={Exchange} alt="exchange" />
                  <span>Exchange</span>
                </a>
              </li>
              <li>
                <a
                  href="/casinogames"
                  className={activePage == "casinogames" ? "active" : ""}
                >
                  <img src={CasinoIcon} alt="Casino" />
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a
                  href="/livecasino"
                  className={activePage == "livecasino" ? "active" : ""}
                >
                  <img src={LiveCasinoIcon} alt="live casino" />
                  <span>Live-games</span>
                </a>
              </li>
              <li className="seprator">
                <div></div>
              </li>
              <li>
                <a
                  href="casino/spribe/aviator"
                  className={`game-logo ${
                    activePage == "aviator" ? "active" : ""
                  }`}
                >
                  <img src={AviatorIcon} alt="aviator" />
                  <span>
                    <img src={AviatorLogo} alt="aviator" />
                  </span>
                </a>
              </li>
              <li className="seprator">
                <div></div>
              </li>
              <li>
                <a
                  href="/casino/evolution"
                  className={activePage == "evolution" ? "active" : ""}
                >
                  <img src={Evolution} alt="evolution" />
                  <span>Evolution</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/ezugi"
                  className={activePage == "ezugi" ? "active" : ""}
                >
                  <img src={Ezugi} alt="ezugi" />
                  <span>Ezugi</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/supernowa"
                  className={activePage == "supernowa" ? "active" : ""}
                >
                  <img src={Supernowa} alt="supernowa" />
                  <span>Supernowa</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/vivo"
                  className={activePage == "vivo" ? "active" : ""}
                >
                  <img src={Vivo} alt="vivo" />
                  <span>Vivo</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/xpg"
                  className={activePage == "xpg" ? "active" : ""}
                >
                  <img src={XPG} alt="xpg" />
                  <span>XPG</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/worldcasino"
                  className={activePage == "worldcasino" ? "active" : ""}
                >
                  <img src={Worldcasino} alt="worldcasino" />
                  <span>Worldcasino</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/qtech"
                  className={activePage == "qtech" ? "active" : ""}
                >
                  <img src={Qtech} alt="qtech" />
                  <span>Qtech</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModals}
        />
      )}
    </>
  );
};

export default LeftBarSports;
