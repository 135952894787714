import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import BottomNav from "../../../../containers/BottomNav";
import BackBtn from "../../../../assets/images/icons/chevron-right.svg";

function IosInstruction() {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="ios-instruction">
          <div className="back">
            <div className="back-btn" onClick={() => navigate(-1)}>
              <img src={BackBtn} alt="back" />
              <span>Main</span>
            </div>
          </div>
          <div className="inner-box">
            <div className="content-box">
              <div className="heading">iOS instruction</div>

              <div className="steps step1">
                <h6>
                  <div className="num">1</div>
                  <span>Open in Safari</span>
                </h6>
                <p>
                  If you are in any other browser, open this page in the Safari
                  browser
                </p>
              </div>
              <div className="steps step1">
                <h6>
                  <div className="num">2</div>
                  <span>Click "Share"</span>
                </h6>
                <p>
                  Tap on the "Share" icon in the menu at the bottom of the
                  screen. A dialog box will open.
                </p>
              </div>
              <div className="steps step1">
                <h6>
                  <div className="num">3</div>
                  <span>Click "Go to Home Screen"</span>
                </h6>
                <p>Tap on the item "On the Home screen", and click "Finish"</p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <BottomNav />

      {/* {!isAuth ? <BeforeLoginFooter /> : <Footer />} */}
    </>
  );
}

export default IosInstruction;
