import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import React, { useRef } from "react";

const styles = {
  visibility: {
    desktop: {
      position: "br", // bottom-right
      xOffset: 15, // 15px away from right
      yOffset: 40, // 40px up from bottom
    },

    mobile: {
      position: "bl", // bottom-left
      xOffset: 5, // 5px away from left
      yOffset: 100, // 50px up from bottom
    },
  },
};
const TawkChat = React.forwardRef((props, ref) => (
  <TawkMessengerReact
    propertyId="66e7c26c50c10f7a00aa9e75"
    widgetId="1i7skuuvj"
    customStyle={styles}
    ref={ref}
  />
));

export default TawkChat;
