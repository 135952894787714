import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HowDeposit from "../../../../assets/images/howto/how-to-deposite-banner.jpg";
import HowDepositMbl from "../../../../assets/images/howto/how-to-deposite.png";

const HowToDeposit = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main how-to-page how-deposit">
        <img src={HowDeposit} alt="how to join" className="d-none d-sm-block" />
        <img src={HowDepositMbl} alt="how to join" className="d-sm-none" />
        <div className="container">
          <div className="how-to-content">
            <div className="topHead">
              <strong>WELCOME USERS, </strong>
              <span>TODAY WE WILL LEARN HOW TO DEPOSIT ON Vipcasino247</span>
            </div>

            <h4>How to deposit?</h4>
            <p>Login to your account first</p>
            <p>
              Click on "Deposit": (Available in the upper right corner of your
              screen & in your profile menu as well)
            </p>

            <p>
              <b>Follow the steps:</b>
            </p>
            <p>
              <b>Two Deposit Options are available</b>
            </p>
            <p>
              1-Auto Deposit: (Instantly deposit your amount to your account)
            </p>
            <p>2-Manual Deposit: (Take some time and Verification)</p>

            <p className="pl-25 mt-4">
              <b>1- In Auto Deposit Option</b>
            </p>
            <p className="pl-40">
              <b>You can deposit using Crypto Deposit:</b>
            </p>
            <ul className="pl-85">
              <li>Alphapo</li>
              <li>Coinbase</li>
            </ul>
            <p className="pl-40 mt-3">
              You can deposit using any crypto currency in just few clicks
              simply click on the particular payment method and follow the steps
            </p>

            <p className="pl-40">
              <b>You can deposit using Bank Transfer:</b>
            </p>
            <ul className="pl-85">
              <li>UPI, Bank Transfer, Gpay & many more</li>
            </ul>
            <p className="pl-40 mt-3">
              You can deposit using any fiat currency (USD, INR, EU and many
              more) in just few clicks. Simply click on the particular payment
              method and follow the steps
            </p>

            <p className="pl-25 mt-4">
              <b>2- In Auto Deposit Option</b>
            </p>
            <ul className="pl-85">
              <li>Live Chat</li>
              <li>Whatsapp</li>
            </ul>

            <p className="mt-3">
              You can use any of the available option for deposit. Send message
              to team and they will credit your account after some verification
              and after payment transfer confirmation. It can take some time
              don’t worry. We process payments very fast and in some cases as
              soon as possible.
            </p>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
};

export default HowToDeposit;
